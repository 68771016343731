<template>
  <div>
    <hero-alt
      img="https://image.freepik.com/vecteurs-libre/salle-du-centre-donnees-racks-serveurs-ecrans-ordinateur-bureau-chaise_107791-3017.jpg"
      title="Console d'administration"
    />

    <features :items="features" />
  </div>
</template>

<script>
export default {
  name: "Notice",

  metaInfo: { title: "Notice" },

  data: () => ({
    features: [
      {
        icon: "mdi-file-eye-outline",
        title: "Références",
        text: "Consulter ou mettre à jour la liste des références"
      },
      {
        icon: "mdi-book-open-page-variant",
        title: "Manuel d'utilisation",
        text: "Téléverser de nouveau manuel d'utilisation"
      },
      {
        icon: "mdi-help-box",
        title: "Foire aux Questions",
        text: "Gerer la liste des questions fréquement posées (non disponible)"
      },
      {
        icon: "mdi-web-box",
        title: "24/7 Support",
        text: "Gerer les variantes internationalisé du site (non disponible)"
      },
      {
        icon: "mdi-database",
        title: "Données d'usage statistique",
        text: "Consulter les KPI du site (non disponible)"
      }
    ]
  }),

  components: {
    HeroAlt: () => import("@/components/mixins/HeroAlt"),
    Features: () => import("@/components/mixins/Features")
  }
};
</script>
